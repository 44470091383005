<template>
  <div>
    <div class="label">基础信息</div>
    <el-form label-width="120px">
      <el-form-item label="分类" required>
        <el-select v-model="params.classifyId2" placeholder="请选择">
          <el-option
            :label="item.name"
            :value="item.id"
            :key="item.id"
            v-for="item in labelList"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="命名" required>
        <el-input v-model="params.name"></el-input>
      </el-form-item>
      <el-form-item label="封面" required>
        <el-upload
          accept=".png,.jpg,.jpeg"
          :show-file-list="false"
          action=""
          :before-upload="
            (file) => {
              handleUpload(file);
              return false;
            }
          "
        >
          <div v-if="params.coverImage">
            <img
              :src="params.coverImage | imgFormat"
              class="review-img"
              style="height: auto"
            />
            <i
              class="el-icon-close"
              @click="params.coverImage = ''"
              @click.stop=""
            ></i>
          </div>
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
            style="font-size: 30px"
          ></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="基础信息">
        <el-input
          v-model="params.basicInfo"
          type="textarea"
          placeholder="百度搜索信息填写，什么科什么的。"
          :rows="5"
        ></el-input>
      </el-form-item>
      <el-form-item label="文化内涵">
        <el-input
          v-model="params.connotation"
          placeholder="玫瑰代表爱情"
        ></el-input>
      </el-form-item>
      <el-form-item label="日常使用搭配">
        <el-input
          v-model="params.collocation"
          placeholder="玫瑰搭配百合"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="label">模型信息</div>
    <el-form label-width="80px">
      <div v-for="(item, index) in detail" :key="index" class="model-item">
        <el-form-item label="轮廓">
          <el-upload
            class="avatar-uploader"
            accept=".png,.jpg,.jpeg"
            :show-file-list="false"
            action=""
            :before-upload="
              (file) => {
                handlePicSuc(file, item);
              }
            "
          >
            <div v-if="item.pointPic">
              <img
                :src="
                  item.pointPic
                    | imgFormat(
                      false,
                      'lvshuhualv.oss-cn-hangzhou.aliyuncs.com'
                    )
                "
                class="review-img"
              />
              <i
                class="el-icon-close"
                @click="item.pointPic = ''"
                @click.stop=""
              ></i>
            </div>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="贴图" v-if="item.type !== 'boli'">
          <el-upload
            class="avatar-uploader"
            accept=".png,.jpg,.jpeg"
            :show-file-list="false"
            action=""
            :before-upload="
              (file) => {
                handleUploadSuc(file, item);
              }
            "
          >
            <div v-if="item.map">
              <img
                :src="
                  item.map
                    | imgFormat(
                      false,
                      'lvshuhualv.oss-cn-hangzhou.aliyuncs.com'
                    )
                "
                class="review-img"
              />
              <i
                class="el-icon-close"
                @click="item.map = ''"
                @click.stop=""
              ></i>
            </div>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </div>
    </el-form>
    <el-button type="success" @click="add">新增</el-button>

    <div class="label">比赛使用建议</div>
    <el-form label-width="120px">
      <el-form-item label="色彩搭配建议">
        <el-input
          v-model="params.colorSuggestion"
          :rows="3"
          type="textarea"
          placeholder="花材为黄色，建议搭配相近色系或"
        ></el-input>
      </el-form-item>
      <el-form-item label="技术使用建议">
        <el-input
          v-model="params.technicalSuggestion"
          :rows="3"
          type="textarea"
          placeholder="玫瑰可以使用铜丝压弯如不小心剪断后可以用铁丝固定"
        ></el-input>
      </el-form-item>
      <el-form-item label="寓意">
        <el-input
          v-model="params.allegory"
          :rows="3"
          type="textarea"
          placeholder="玫瑰象征着爱情，或热烈，或含蓄"
        ></el-input>
      </el-form-item>
      <el-form-item label="构图建议">
        <el-input
          v-model="params.compositionSuggestion"
          :rows="3"
          type="textarea"
          placeholder="玫瑰叶大，花大，适合在在怎"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="ls_flex" v-if="!isPublic">
      <el-button type="primary" @click="save(1)">新增并发布</el-button>
      <el-button type="primary" @click="save(2)">存储待发</el-button>
    </div>

    <canvas width="6000" height="10000" id="c" style="display: none"></canvas>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      detail: [],
      params: {
        classifyId2: "",
        name: "",
        coverImage: "",
        basicInfo: "",
        connotation: "",
        collocation: "",
        colorSuggestion: "",
        technicalSuggestion: "",
        allegory: "",
        compositionSuggestion: "",
        detail: "",
      },
      form: {},
      labelList: [],
      ossUploadData: {},
    };
  },
  created() {
    this.getLabelList();
    this.getKey();
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.getDetail(this.id);
    }
  },
  methods: {
    getDetail(id) {
      this.$ajax.get("/admin/materialManage/getById?id=" + id).then((res) => {
        const resData = res.data;
        for (const key in this.params) {
          this.params[key] = resData[key];
        }
        this.init();
      });
    },
    init() {
      if (this.params.detail) {
        this.detail = JSON.parse(this.params.detail);
      }
    },
    getKey() {
      this.$ajax.post("/user/oss/getKey?pathName=cover").then(async (res) => {
        this.ossUploadData.cover = res.data;
      });

      this.$ajax.post("/user/oss/getKey?pathName=common").then(async (res) => {
        this.ossUploadData.material = res.data;
      });
    },
    //图片轮廓上传成功
    handlePicSuc(file, item) {
      this.getUploadPromise(
        {
          file,
        },
        this.ossUploadData.material
      ).then((res) => {
        const canvas = document.getElementById("c");
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, c.width, c.height);
        const threeImg = new Image();
        // 改变图片的src
        threeImg.src = Vue.filter("imgFormat")(
          res.addr,
          false,
          "lvshuhualv.oss-cn-hangzhou.aliyuncs.com"
        );
        threeImg.crossOrigin = "Anonymous";
        // 加载完成执行
        threeImg.onload = () => {
          ctx.drawImage(threeImg, 0, 0, threeImg.width, threeImg.height);
          const imgData = ctx.getImageData(
            0,
            0,
            threeImg.width,
            threeImg.height
          );
          const point = [];
          for (let y = 0; y < canvas.height; y++) {
            let test = 0;
            for (let x = 0; x < canvas.width; x++) {
              const i = y * canvas.width + x;
              if (imgData.data[4 * i + 3]) {
                test = x;
              }
            }
            if (test) {
              point.push(test);
            }
          }
          item.points = point;
        };
        item.pointPic = res.addr;
      });
    },
    handleUpload(file) {
      this.getUploadPromise(
        {
          file,
        },
        this.ossUploadData.cover
      ).then((res) => {
        this.params.coverImage = res.addr;
      });
    },
    //贴图上传成功
    handleUploadSuc(file, item) {
      this.getUploadPromise(
        {
          file,
        },
        this.ossUploadData.material
      ).then((res) => {
        item.map = res.addr;
      });
    },
    add() {
      this.detail.push({
        map: "",
        pointPic: "",
        points: [],
      });
    },
    getLabelList() {
      this.$ajax.post("/admin/classifyManage/queryAll").then((res) => {
        this.labelList = res.data.find((item) => item.id === 2).child;
      });
    },
    save(status) {
      this.$ajax
        .post(this.id ? "/material/batchUpdate" : "/material/save", {
          ...this.params,
          detail: this.detail.length ? JSON.stringify(this.detail) : "",
          classifyId1: 2,
          status,
          ids: this.id ? [this.id] : "",
        })
        .then((res) => {
          this.$message.success("保存成功");
          this.$router.push("/materials/container/list");
        });
    },
  },
  computed: {
    isPublic() {
      return this.$route.query.isPublic;
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 20px;
  color: #999;
  margin: 15px 0;
}
.model {
  padding: 15px 0;
}

h1 {
  text-align: center;
  font-size: 30px;
  padding: 15px 0;
}

.el-button {
  width: 100%;
}

.review-img {
  height: 150px;
}

.model-item {
  padding: 15px 0;
  border-bottom: 3px solid #000;
}
</style>